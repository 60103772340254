@use "~@otto-ec/global-frontend-settings/scss/variables" as vars;
@use "~@otto-ec/global-frontend-settings/scss/grid" as grid;
@use "~@otto-ec/global-frontend-settings/gfs" as gfs;

.nav-bottom-links {
  padding: 0 16px;
  background-color: #fff;

  p, a {
    margin: 0;
    font-size: 14px;
    padding: 8px 0;
  }

  p a {
    padding: 0;
    text-decoration: underline;
  }

  a {
    text-decoration: none;
    display: inline-block;
  }

  &:before {
    content: "";
    display: block;
    clear: both;
    visibility: hidden;
  }
}

/*                                                  */
.nav-bottom-links .nav-link-boxes {
  display: flex;
  color: vars.$black100;
  flex-wrap: wrap;

  .nav-bottom-link {
    flex: 1 1 100%;
    padding: 16px 0;

    &:last-of-type {
      border-bottom: 0;
    }

    @include gfs.breakpoint(gfs.$breakpoint-m) {
      flex-basis: 50%;
    }

    @include gfs.breakpoint(gfs.$breakpoint-l) {
      flex-basis: 33.33%;
    }
  }
}
